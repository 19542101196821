var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('base-header',{staticClass:"py-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-2 col-md-6"},[_c('stats-card',{attrs:{"title":"vị trí TB","type":"gradient-info","icon":"ni ni-chart-bar-32"}},[_c('template',{slot:"footer"},[_c('span',{class:[
                _vm.averagePositionDiff < 0 ? 'text-success' : '',
                _vm.averagePositionDiff > 0 ? 'text-danger' : '',
                'mr-2',
              ]},[(_vm.averagePositionDiff < 0)?_c('i',{staticClass:"fa fa-arrow-up"}):_vm._e(),(_vm.averagePositionDiff > 0)?_c('i',{staticClass:"fa fa-arrow-down"}):_vm._e(),_vm._v(" "+_vm._s(_vm.latestTracking && _vm.latestTracking.average_position !== null ? _vm.latestTracking.average_position : "N/A")+" ")]),_c('br'),(
                _vm.previousTracking && _vm.previousTracking.average_position !== null
              )?_c('span',{staticClass:"text-nowrap"},[_vm._v("vs "+_vm._s(_vm.previousTracking.average_position)+" (thay đổi "+_vm._s(!isNaN(_vm.averagePositionDiff) ? _vm.averagePositionDiff : "N/A")+")")]):_vm._e()])],2)],1),_c('div',{staticClass:"col-xl-2 col-md-6"},[_c('stats-card',{attrs:{"title":"Tăng","type":"gradient-red","icon":"ni ni-active-40"}},[_c('template',{slot:"footer"},[_c('span',{class:[
                _vm.rankUpDiff > 0 ? 'text-success' : '',
                _vm.rankUpDiff < 0 ? 'text-danger' : '',
                'mr-2',
              ]},[(_vm.rankUpDiff > 0)?_c('i',{staticClass:"fa fa-arrow-up"}):_vm._e(),(_vm.rankUpDiff < 0)?_c('i',{staticClass:"fa fa-arrow-down"}):_vm._e(),_vm._v(" "+_vm._s(_vm.latestTracking && _vm.latestTracking.rank_up_keywords !== null ? _vm.latestTracking.rank_up_keywords : "N/A")+" ")]),_c('br'),(
                _vm.previousTracking && _vm.previousTracking.rank_up_keywords !== null
              )?_c('span',{staticClass:"text-nowrap"},[_vm._v("vs "+_vm._s(_vm.previousTracking.rank_up_keywords)+" (thay đổi "+_vm._s(_vm.rankUpDiff)+")")]):_vm._e()])],2)],1),_c('div',{staticClass:"col-xl-2 col-md-6"},[_c('stats-card',{attrs:{"title":"Top 3","type":"gradient-orange","icon":"ni ni-world-2"}},[_c('template',{slot:"footer"},[_c('span',{class:[
                _vm.top3Diff > 0 ? 'text-success' : '',
                _vm.top3Diff < 0 ? 'text-danger' : '',
                'mr-2',
              ]},[(_vm.top3Diff > 0)?_c('i',{staticClass:"fa fa-arrow-up"}):_vm._e(),(_vm.top3Diff < 0)?_c('i',{staticClass:"fa fa-arrow-down"}):_vm._e(),_vm._v(" "+_vm._s(_vm.latestTracking && _vm.latestTracking.top_3_keywords !== null ? _vm.latestTracking.top_3_keywords : "N/A")+" ")]),_c('br'),(
                _vm.previousTracking && _vm.previousTracking.top_3_keywords !== null
              )?_c('span',{staticClass:"text-nowrap"},[_vm._v("vs "+_vm._s(_vm.previousTracking.top_3_keywords)+" (thay đổi "+_vm._s(_vm.top3Diff)+")")]):_vm._e()])],2)],1),_c('div',{staticClass:"col-xl-2 col-md-6"},[_c('stats-card',{attrs:{"title":"Top 10","type":"gradient-green","icon":"ni ni-chart-pie-35"}},[_c('template',{slot:"footer"},[_c('span',{class:[
                _vm.top10Diff > 0 ? 'text-success' : '',
                _vm.top10Diff < 0 ? 'text-danger' : '',
                'mr-2',
              ]},[(_vm.top10Diff > 0)?_c('i',{staticClass:"fa fa-arrow-up"}):_vm._e(),(_vm.top10Diff < 0)?_c('i',{staticClass:"fa fa-arrow-down"}):_vm._e(),_vm._v(" "+_vm._s(_vm.latestTracking && _vm.latestTracking.top_10_keywords !== null ? _vm.latestTracking.top_10_keywords : "N/A")+" ")]),_c('br'),(
                _vm.previousTracking && _vm.previousTracking.top_10_keywords !== null
              )?_c('span',{staticClass:"text-nowrap"},[_vm._v("vs "+_vm._s(_vm.previousTracking.top_10_keywords)+" (thay đổi "+_vm._s(_vm.top10Diff)+")")]):_vm._e()])],2)],1),_c('div',{staticClass:"col-xl-2 col-md-6"},[_c('stats-card',{attrs:{"title":"Top 30","type":"gradient-purple","icon":"ni ni-world"}},[_c('template',{slot:"footer"},[_c('span',{class:[
                _vm.top30Diff > 0 ? 'text-success' : '',
                _vm.top30Diff < 0 ? 'text-danger' : '',
                'mr-2',
              ]},[(_vm.top30Diff > 0)?_c('i',{staticClass:"fa fa-arrow-up"}):_vm._e(),(_vm.top30Diff < 0)?_c('i',{staticClass:"fa fa-arrow-down"}):_vm._e(),_vm._v(" "+_vm._s(_vm.latestTracking && _vm.latestTracking.top_30_keywords !== null ? _vm.latestTracking.top_30_keywords : "N/A")+" ")]),_c('br'),(
                _vm.previousTracking && _vm.previousTracking.top_30_keywords !== null
              )?_c('span',{staticClass:"text-nowrap"},[_vm._v("vs "+_vm._s(_vm.previousTracking.top_30_keywords)+" (thay đổi "+_vm._s(_vm.top30Diff)+")")]):_vm._e()])],2)],1),_c('div',{staticClass:"col-xl-2 col-md-6"},[_c('stats-card',{attrs:{"title":"Top 100","type":"gradient-teal","icon":"ni ni-planet"}},[_c('template',{slot:"footer"},[_c('span',{class:[
                _vm.top100Diff > 0 ? 'text-success' : '',
                _vm.top100Diff < 0 ? 'text-danger' : '',
                'mr-2',
              ]},[(_vm.top100Diff > 0)?_c('i',{staticClass:"fa fa-arrow-up"}):_vm._e(),(_vm.top100Diff < 0)?_c('i',{staticClass:"fa fa-arrow-down"}):_vm._e(),_vm._v(" "+_vm._s(_vm.latestTracking && _vm.latestTracking.top_100_keywords !== null ? _vm.latestTracking.top_100_keywords : "N/A")+" ")]),_c('br'),(
                _vm.previousTracking && _vm.previousTracking.top_100_keywords !== null
              )?_c('span',{staticClass:"text-nowrap"},[_vm._v("vs "+_vm._s(_vm.previousTracking.top_100_keywords)+" (thay đổi "+_vm._s(_vm.top100Diff)+")")]):_vm._e()])],2)],1)])]),_c('div',{staticClass:"container-fluid mt--6"},[_c('div',[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('template',{slot:"header"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Danh sách từ khóa")])]),_c('div',[_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"placeholder":"Per page"},on:{"change":_vm.handleFilter},model:{value:(_vm.listQuery.limit),callback:function ($$v) {_vm.$set(_vm.listQuery, "limit", $$v)},expression:"listQuery.limit"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1),_c('div',[_c('base-input',{attrs:{"prepend-icon":"fas fa-search","placeholder":"Tìm kiếm..."},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleFilter.apply(null, arguments)}},model:{value:(_vm.listQuery.search),callback:function ($$v) {_vm.$set(_vm.listQuery, "search", $$v)},expression:"listQuery.search"}})],1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.listLoading),expression:"listLoading"}],attrs:{"data":_vm.tableData,"row-key":"id","header-row-class-name":"thead-light"},on:{"sort-change":_vm.sortChange,"selection-change":_vm.selectionChange}},[_c('el-table-column',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
                    name: 'ViewShareKeyword',
                    params: { id: scope.row.id },
                  }}},[_vm._v(_vm._s(scope.row.keyword))])]}}])},'el-table-column',{
                label: 'Từ khóa',
                minWidth: 200,
                sortable: true,
              },false)),_c('el-table-column',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.search_volume || "N/A")+" ")]}}])},'el-table-column',{
                label: 'Search Volume',
                minWidth: 120,
                sortable: true,
              },false)),_c('el-table-column',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{class:[
                    scope.row.rank_diff < 0 ? 'text-success' : '',
                    scope.row.rank_diff > 0 ? 'text-danger' : '',
                    'mr-2',
                  ]},[(scope.row.rank_diff < 0)?_c('i',{staticClass:"fa fa-arrow-up"}):_vm._e(),(scope.row.rank_diff > 0)?_c('i',{staticClass:"fa fa-arrow-down"}):_vm._e(),_vm._v(" "+_vm._s(scope.row.rank_diff && scope.row.rank_diff !== null && scope.row.rank_diff > 0 ? "-" : "")+" "+_vm._s(scope.row.rank_diff && scope.row.rank_diff !== null && scope.row.rank_diff < 0 ? "+" : "")+" "+_vm._s(scope.row.rank_diff && scope.row.rank_diff !== null ? Math.abs(scope.row.rank_diff) : "N/A")+" ")])]}}])},'el-table-column',{
                label: 'Thay đổi TH',
                minWidth: 100,
                sortable: true,
              },false)),_c('el-table-column',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.current_rank && scope.row.current_rank !== null ? scope.row.current_rank : "N/A")+" ")]}}])},'el-table-column',{
                label: 'TH mới nhất',
                minWidth: 100,
                sortable: true,
              },false)),_c('el-table-column',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.best_rank && scope.row.best_rank !== null ? scope.row.best_rank : "N/A")+" ")]}}])},'el-table-column',{
                label: 'TH cao nhất',
                minWidth: 100,
                sortable: true,
              },false)),_c('el-table-column',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.first_rank && scope.row.first_rank !== null ? scope.row.first_rank : "N/A")+" ")]}}])},'el-table-column',{
                label: 'TH ban đầu',
                minWidth: 100,
                sortable: true,
              },false)),_c('el-table-column',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.updated_at)+" ")]}}])},'el-table-column',{
                label: 'Cập nhật',
                minWidth: 200,
                sortable: true,
              },false)),_c('el-table-column',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.tracking && scope.row.tracking.found_url ? scope.row.tracking.found_url : "Không tìm thấy")+" ")]}}])},'el-table-column',{
                label: 'URL tìm thấy',
                minWidth: 160,
                sortable: true,
              },false))],1)],1),_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{},[_c('p',{staticClass:"card-category"},[_vm._v("Tổng cộng: "+_vm._s(_vm.pagination.total))])]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"page-count":_vm.pagination.pageCount,"per-page":_vm.pagination.perPage,"total":_vm.pagination.total},on:{"input":_vm.getTerms},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)],2)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }